import React from 'react';

import { team } from 'config/team-config';

export default function Team() {
  return (
    <div className="team-wrap">
      <ul>
        {team.map(item => {
          const { image, name, linkedin, position } = item;

          const url = require(`assets/images/team/${image}.jpg`);

          return (
            <li key={name}>
              <a href={linkedin} target="_blank" rel="noreferrer">
                <img src={url} alt="" />
                <h1>{name}</h1>
                <p>{position}</p>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
