import React from 'react';

export default function About() {
  return (
    <div className="about-wrap">
      <p>
        Experienced Senior Front-End Developer with a decade of expertise in the banking, gambling
        and digital wallet industries. Proficient in JavaScript, React, React Native, CSS and HTML,
        including CSS preprocessors. Specialized in creating mobile banking apps, admin panels and
        casino platforms, with a strong focus on user-centric design and functionality. Recognized
        for delivering high-quality solutions and contributing to successful projects.
      </p>
    </div>
  );
}
