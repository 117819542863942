import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { loadLocalStorage } from 'utils/local-storage';

function TerminalInput(props) {
  const { executeCommand } = props;

  const terminalInputRef = useRef(null);
  const terminalHistoryRef = useRef(null);

  const [isHistoryOpen, setIsHistoryOpen] = useState(false);
  const [fishText, setFishText] = useState('');

  const directory = loadLocalStorage('directory') || '';
  const history = loadLocalStorage('history') || [];
  const path = `~/portfolio${directory.length ? `/${directory}` : ''}`;

  const focusEnd = element => {
    element.focus();
    window.getSelection().selectAllChildren(element);
    window.getSelection().collapseToEnd();
  };

  const focusOptions = () => {
    if (terminalHistoryRef.current) {
      terminalHistoryRef.current.focus();

      const { options } = terminalHistoryRef.current;

      const lastOption = options[options.length - 1];

      if (history.length > 0) {
        terminalInputRef.current.textContent = lastOption.textContent.replace(/&nbsp;/g, ' ');

        lastOption.selected = true;
      }
    }
  };

  const handleTerminalInputChange = event => {
    const value = event.target.textContent.replace(/&nbsp;/g, ' ');

    if (value) {
      const founded = history.reverse().find(item => item.startsWith(value));

      if (founded && founded.length) {
        setFishText(founded);
      } else {
        setFishText('');
      }
    } else {
      setFishText('');
    }

    if (isHistoryOpen) setIsHistoryOpen(false);
  };

  const onOptionKeyUp = event => {
    const { selectedIndex } = terminalHistoryRef.current.options;

    if (terminalHistoryRef.current.options[selectedIndex]) {
      const { value } = terminalHistoryRef.current.options[selectedIndex];

      terminalInputRef.current.textContent = value.replace(/&nbsp;/g, ' ');
    }

    if (event.key !== 'ArrowUp' && event.key !== 'ArrowDown' && selectedIndex) {
      setIsHistoryOpen(false);
      terminalInputRef.current.focus();
    }
  };

  const onOptionClick = (event, item) => {
    event.stopPropagation();

    terminalInputRef.current.textContent = item.replace(/&nbsp;/g, ' ');
    setIsHistoryOpen(false);
  };

  useEffect(() => {
    const terminalInput = terminalInputRef.current;

    const handleKeyPress = event => {
      if (event.key === 'ArrowUp' && !terminalHistoryRef.current) {
        setFishText('');
        setIsHistoryOpen(true);
      }

      if (event.key === 'Enter' && terminalInput) {
        event.preventDefault();

        executeCommand(terminalInput.textContent.replace(/&nbsp;/g, ' '));
        terminalInput.textContent = '';
        setFishText('');
      }

      if ((event.key === 'ArrowRight' || event.key === 'Tab') && terminalInput && fishText) {
        event.preventDefault();

        terminalInput.textContent = fishText;

        focusEnd(terminalInput);
      }
    };

    if (terminalInput) {
      terminalInput.focus();

      terminalInput.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      if (terminalInput) {
        terminalInput.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [executeCommand, fishText]);

  useEffect(() => {
    if (terminalHistoryRef.current && isHistoryOpen) {
      focusOptions();
    } else {
      focusEnd(terminalInputRef.current);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHistoryOpen]);

  useEffect(() => {
    function handleEscapeKeyPress(event) {
      if (event.key === 'Escape') {
        if (terminalHistoryRef) {
          setIsHistoryOpen(false);
          focusEnd(terminalInputRef.current);
        }
      }
    }

    window.addEventListener('keydown', handleEscapeKeyPress);

    return () => {
      window.removeEventListener('keydown', handleEscapeKeyPress);
    };
  }, []);

  return (
    <>
      <div
        className="terminal-input-wrap"
        onClick={() => !terminalHistoryRef.current && focusEnd(terminalInputRef.current)}
      >
        <p>{path}</p>

        <div
          className="terminal-input"
          contentEditable
          ref={terminalInputRef}
          onInput={event => handleTerminalInputChange(event)}
        />

        <div className="terminal-input fish-text">{fishText}</div>
      </div>

      {isHistoryOpen ? (
        <div
          className="terminal-history-popup"
          onClick={event => {
            event.stopPropagation();
            setIsHistoryOpen(false);
          }}
        >
          <select
            className="terminal-history-wrap"
            name="history"
            multiple
            size="8"
            ref={terminalHistoryRef}
            onKeyUp={event => onOptionKeyUp(event)}
          >
            {history.map(item => (
              <option value={item} key={item} onClick={event => onOptionClick(event, item)}>
                {item}
              </option>
            ))}
          </select>
        </div>
      ) : null}
    </>
  );
}

TerminalInput.propTypes = {
  executeCommand: PropTypes.func.isRequired,
};

export default TerminalInput;
