import About from 'ui/about';
import Contacts from 'ui/contacts';
import Experience from 'ui/experience';
import Skills from 'ui/skills';
import Team from 'ui/team';

export const directories = [
  {
    name: 'about',
    path: 'about',
    type: 'folder',
    children: [
      {
        name: 'about-me.html',
        type: 'file',
        contentText: <About />,
      },
    ],
  },
  {
    name: 'skills',
    path: 'skills',
    type: 'folder',
    children: [
      {
        name: 'skills.html',
        type: 'file',
        contentText: <Skills />,
      },
    ],
  },
  {
    name: 'contacts.html',
    type: 'file',
    contentText: <Contacts />,
  },
  {
    name: 'experience',
    path: 'experience',
    type: 'folder',
    children: [
      {
        name: 'experience.html',
        type: 'file',
        contentText: <Experience />,
      },
    ],
  },
  {
    name: 'team',
    path: 'team',
    type: 'folder',
    children: [
      {
        name: 'team.html',
        type: 'file',
        contentText: <Team />,
      },
    ],
  },
];
