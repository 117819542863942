import React from 'react';

import { experience } from 'config/experience-config';

export default function Experience() {
  return (
    <div className="experience-wrap">
      <ul>
        {experience.map(item => {
          const { date, employer, list, position } = item;

          return (
            <li key={employer}>
              <p>{position}</p>
              <p>
                {employer}
                {date}
              </p>

              {list ? (
                <ul>
                  {list.map(listItem => (
                    <li key={listItem}>{listItem}</li>
                  ))}
                </ul>
              ) : null}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
