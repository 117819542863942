import React from 'react';

const contacts = [
  {
    image: 'email',
    path: 'mailto:mkrtchyan.aram89@gmail',
  },
  {
    image: 'phone',
    path: 'tel:(+374) 98-78-67-21',
  },
  {
    image: 'linkedin',
    path: 'https://www.linkedin.com/in/arammkrtchyan/',
  },
  {
    image: 'stackoverflow',
    path: 'https://stackoverflow.com/users/1035619/aram-mkrtchyan',
  },
  {
    image: 'github',
    path: 'https://github.com/decoder10',
  },
];

export default function Contacts() {
  return (
    <div className="contacts-wrap">
      {contacts.map(item => {
        const { path, image } = item;

        const url = require(`assets/images/contacts/${image}.svg`);

        return (
          <a href={path} target="_blank" key={image} rel="noreferrer">
            <img src={url} alt="" />
          </a>
        );
      })}
    </div>
  );
}
