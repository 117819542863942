import 'assets/styles/portfolio.scss';
import { useState } from 'react';

import { directories } from 'config/portfolio-config';

import { loadLocalStorage, saveLocalStorage } from 'utils/local-storage';

import TerminalCommandsList from 'components/terminal-commands-list';
import TerminalInput from 'components/terminal-input';

import About from 'ui/about';
import Contacts from 'ui/contacts';
import Experience from 'ui/experience';
import Help from 'ui/help';
import Skills from 'ui/skills';
import Team from 'ui/team';

function App() {
  const [commandsList, setCommandList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const createCommandHistory = value => {
    const _value = value.trim();
    const history = loadLocalStorage('history') || [];
    const commandExist = history.find(item => item === _value);

    if (!commandExist) {
      saveLocalStorage('history', [...history, ...[_value]]);
    } else {
      const _history = history.filter(item => item !== _value);

      saveLocalStorage('history', [..._history, ...[_value]]);
    }
  };

  const executeCommandClear = () => setCommandList([]);

  const executeCommandList = (path, value, _value, directory) => {
    const data =
      path === '~/portfolio'
        ? [
            {
              path,
              command: value,
              status: '',
              contentList: directories,
              type: _value === 'ls' ? 'inline' : 'block',
            },
          ]
        : [
            {
              path,
              command: value,
              status: '',
              contentList: directories.find(item => item.path === directory).children || [],
              type: _value === 'ls' ? 'inline' : 'block',
            },
          ];

    setCommandList([...commandsList, ...data]);

    return true;
  };

  const executeCommandPath = (path, splitValues, value) => {
    const _splitValues = splitValues[1]?.trim().replace('/', '');
    const foundedItem = directories.find(item => item.path?.trim() === _splitValues);

    if (
      (path === '~/portfolio' && foundedItem && foundedItem.type === 'folder') ||
      (foundedItem && foundedItem.type === 'folder' && splitValues[1].startsWith('/'))
    ) {
      saveLocalStorage('directory', _splitValues);

      setCommandList([
        ...commandsList,
        ...[
          {
            path,
            command: value,
            status: '',
          },
        ],
      ]);

      return true;
    }

    return false;
  };

  const executeCommandPathExit = (path, value) => {
    saveLocalStorage('directory', '');

    setCommandList([
      ...commandsList,
      ...[
        {
          path,
          command: value,
          status: '',
        },
      ],
    ]);

    return true;
  };

  const executeCommandOpen = (path, splitValues, directory, value) => {
    const commandValue = splitValues[1].split('/');
    const foundedItem = directories.find(item => item.name.trim() === splitValues[1]?.trim());
    const foundedParent = directories.find(item => item.path === directory);
    const newCommand = [
      {
        path,
        command: value,
        status: '',
      },
    ];

    if (path === '~/portfolio' && commandValue.length === 2) {
      const _foundedParent = directories.find(item => item.path === commandValue[0]);
      const children = _foundedParent.children.find(
        item => item.name.trim() === commandValue[1]?.trim(),
      );

      if (children) {
        saveLocalStorage('directory', commandValue[0].trim());
        setIsModalOpen(true);
        setModalContent(commandValue[1]?.trim());

        setCommandList([...commandsList, ...newCommand]);

        return true;
      }
    }

    if (path === '~/portfolio' && foundedItem && foundedItem.type === 'file') {
      setIsModalOpen(true);
      setModalContent(splitValues[1]?.trim());

      setCommandList([...commandsList, ...newCommand]);

      return true;
    }

    if (foundedParent && Object.keys(foundedParent).length) {
      const children = foundedParent.children.find(
        item => item.name.trim() === splitValues[1]?.trim(),
      );

      if (children && children.type === 'file') {
        setIsModalOpen(true);
        setModalContent(children.name.trim());

        setCommandList([...commandsList, ...newCommand]);

        return true;
      }
    }

    return false;
  };

  const executeCommandCat = (path, splitValues, directory, value) => {
    const commandValue = splitValues[1].split('/');
    const foundedItem = directories.find(item => item.name.trim() === splitValues[1]?.trim());
    const foundedParent = directories.find(item => item.path === directory);

    if (path === '~/portfolio' && foundedItem && foundedItem.type === 'file') {
      setCommandList([
        ...commandsList,
        ...[
          {
            path,
            command: value,
            status: '',
            contentText: foundedItem.contentText,
            type: 'inline',
          },
        ],
      ]);

      return true;
    }

    if (path === '~/portfolio' && commandValue.length === 2) {
      const _foundedParent = directories.find(item => item.path === commandValue[0]);
      const children = _foundedParent.children.find(
        item => item.name.trim() === commandValue[1]?.trim(),
      );

      if (children) {
        saveLocalStorage('directory', commandValue[0].trim());

        setCommandList([
          ...commandsList,
          ...[
            {
              path: `~/portfolio/${commandValue[0]}`,
              command: value,
              status: '',
              contentText: children?.contentText || null,
              type: 'inline',
            },
          ],
        ]);

        return true;
      }
    }

    if (foundedParent && Object.keys(foundedParent).length) {
      const children = foundedParent.children.find(
        item => item.name.trim() === splitValues[1]?.trim(),
      );

      if (children && children.type === 'file') {
        setCommandList([
          ...commandsList,
          ...[
            {
              path,
              command: value,
              status: '',
              contentText: children.contentText,
              type: 'inline',
            },
          ],
        ]);

        return true;
      }
    }

    return false;
  };

  const validateCommand = value => {
    const _value = value.toLowerCase().trim();
    const splitValues = _value.split(' ');
    const directory = loadLocalStorage('directory') || '';
    const path = `~/portfolio${directory.length ? `/${directory}` : ''}`;

    switch (_value) {
      case 'clear':
      case 'cl':
        return executeCommandClear();
      case 'ls':
      case 'ls -la':
        return executeCommandList(path, value, _value, directory);
      case 'cd ..':
      case 'cd':
      case 'cd /':
        return executeCommandPathExit(path, value);
      case 'exit':
        setCommandList([
          ...commandsList,
          ...[
            {
              path,
              command: value,
              status: '',
            },
          ],
        ]);

        return (window.location.href = 'https://arams.website');
      case 'help':
        setCommandList([
          ...commandsList,
          ...[
            {
              path,
              command: value,
              status: '',
            },
          ],
        ]);

        setModalContent('help');
        setIsModalOpen(true);

        return true;
      default:
        if (_value.startsWith('cd')) {
          const _executeCommandPath = executeCommandPath(path, splitValues, value);
          if (_executeCommandPath) return true;
        }

        if (_value.startsWith('open')) {
          const _executeCommandOpen = executeCommandOpen(path, splitValues, directory, value);
          if (_executeCommandOpen) return true;
        }

        if (_value.startsWith('cat')) {
          const _executeCommandCat = executeCommandCat(path, splitValues, directory, value);

          if (_executeCommandCat) return true;
        }

        return setCommandList([
          ...commandsList,
          {
            path,
            command: value,
            status: value.length ? 'error' : '',
            errorText: value.length ? `zsh: command not found: ${value}` : '',
          },
        ]);
    }
  };

  const handleExecuteCommand = value => {
    if (value.length) createCommandHistory(value);

    validateCommand(value);
  };

  return (
    <div className="portfolio-wrap">
      <TerminalCommandsList commandsList={commandsList} />

      <TerminalInput executeCommand={handleExecuteCommand} />

      {isModalOpen ? (
        <div className="modal">
          <button className="close-button" type="button" onClick={() => setIsModalOpen(false)} />

          <div className="modal-content">
            {modalContent === 'contacts.html' ? <Contacts /> : null}
            {modalContent === 'about-me.html' ? <About /> : null}
            {modalContent === 'skills.html' ? <Skills /> : null}
            {modalContent === 'experience.html' ? <Experience /> : null}
            {modalContent === 'team.html' ? <Team /> : null}
            {modalContent === 'help' ? <Help /> : null}
          </div>
        </div>
      ) : null}

      <button
        className="help"
        type="button"
        onClick={() => {
          setModalContent('help');
          setIsModalOpen(true);
        }}
      >
        ?
      </button>
    </div>
  );
}

export default App;
