import React, { useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

export default function TerminalCommandsList(props) {
  const { commandsList } = props;

  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, [commandsList]);

  return (
    <div className="commands-wrap" ref={containerRef}>
      <ul>
        {commandsList.map((item, index) => {
          const {
            status = '',
            path,
            command,
            errorText,
            contentList,
            type = '',
            contentText,
          } = item;

          return (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index} className={status}>
              <p className="path">{path}</p>

              <p className="command">{command}</p>

              {errorText && errorText.length ? <p className="error-text">{errorText}</p> : null}

              {contentList && contentList.length ? (
                <div className="terminal-commands-list">
                  {contentList.map(contentListItem => {
                    return (
                      <div key={contentListItem.path || contentListItem.name} className={type}>
                        {type === 'block' ? (
                          <>
                            {contentListItem.type === 'folder' ? (
                              <i>drwxr-xr-x</i>
                            ) : (
                              <i>-rwxr-xr-x</i>
                            )}
                            <i>1</i>
                            <i>aram</i>
                            <i>Aug 31 07:38</i>
                          </>
                        ) : null}

                        <span className={contentListItem.type}>{contentListItem.name}</span>
                      </div>
                    );
                  })}
                </div>
              ) : null}

              {contentText || null}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

TerminalCommandsList.propTypes = {
  commandsList: PropTypes.arrayOf(PropTypes.object).isRequired,
};
