import React from 'react';

const skills = [
  { name: 'HTML', image: 'html' },
  { name: 'CSS', image: 'css' },
  { name: 'SASS', image: 'sass' },
  { name: 'JavaScript', image: 'js' },
  { name: 'TypeScript', image: 'typescript' },
  { name: 'React / React Native', image: 'react' },
];

export default function Skills() {
  return (
    <div className="skills-wrap">
      <ul>
        {skills.map(item => {
          const { image, name } = item;

          const url = require(`assets/images/skills/${image}.svg`);

          return (
            <li key={name}>
              <div className="image-wrap">
                <img src={url} alt="" />
              </div>

              <span>{name}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
