import React from 'react';

export default function Help() {
  return (
    <div className="help-wrap">
      <h1>Terminal Commands</h1>

      <ul>
        <li>help</li>
        <li>exit</li>
        <li>ls -la / ls</li>
        <li>cd / cd ..</li>
        <li>cat [file].html / open [file].html / open [path]/[file].html</li>
        <li>clear / cl</li>
        <li>&ldquo;Arrow up&ldquo; to see history</li>
      </ul>
    </div>
  );
}
